import * as React from "react";
import {
  mapChecklist,
  mapSession,
  useGetAssignedChecklistComposite,
  useSession,
} from "../../services/checklistService";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  List,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import ChecklistOverviewListItem from "./ChecklistOverviewListItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSignatureContext } from "../../../signature-react/SignatureContext";
import { ChecklistCommentOrDeviation } from "../ReportDeviationDialog";
import { Deviation } from "../deviations/useChecklistDeviations";
import DeviationAccordion from "../deviations/DeviationAccordion";
import ChecklistDeviationAccordion from "../deviations/ChecklistDeviationAccordion";
import { useChecklistContext } from "../../ChecklistReactWrapper";

type Props = {
  sessionData: any;
  checklist: any;
  sessionDeviations: Deviation[];
  pointNameRegistry: { [key: string]: string };
};

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    chip: {
      width: 110,
      marginRight: spacing(2),
    },
  })
);

const ChecklistOverviewCard = ({ sessionData, checklist, sessionDeviations, pointNameRegistry }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { tenantId } = useChecklistContext();

  const [{ data: sessionEvents, loading, error }] = useSession(sessionData.id);

  const convertDateTime = (timestamp) =>
    DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_SHORT);

  const [
    { data: checklistData, loading: checklistLoading, error: checklistError },
  ] = useGetAssignedChecklistComposite(checklist.assignedId, DateTime.fromISO(sessionData.timestamp));

  // find the first signature id in the session events
  const signatureId = sessionEvents?.find((e) => !!e.signatureId)?.signatureId;

  const { state } = useSignatureContext()
  const signature = state.signatures.find((s) => s.id === signatureId);

  if (loading || checklistLoading) {
    return <p>{t("Loading")}</p>;
  }
  if (error || checklistError) {
    return <p>{t("Error")}</p>;
  }
  const reportChecklist = mapChecklist(checklistData, checklist.assignedId);

  const session = mapSession(sessionEvents, reportChecklist);

  const progress = (session) => {
    let checked = 0;
    session.map((item) => {
      item.checked == true && checked++;
    });
    return `${checked}/${session.length}`;
  };


  const sessionId = sessionData?.sessionId;
  const deviations = (sessionId && sessionDeviations) ? sessionDeviations.filter(sd => sessionId === sd.sessionId).length : 0;

  const comments = sessionEvents?.filter(
    (e) =>
      e.type === "ChecklistComment"
  )

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div>
          <div>
            <Chip label={t(`checklist.status.${sessionData.status}`)} className={classes.chip} />
            <b>{convertDateTime(
              sessionData.timestamp
            )}</b>{" "}
            <span>{` - Fullført: ${progress(session)} - Avvik: ${deviations}`}</span>
          </div>
          <div>
            <Chip label={t(`checklist.status.${sessionData.status}`)} className={classes.chip} style={{
              visibility: "hidden"
            }} />
            <i>{signature ? `av ${signature.name} ` : ""}</i>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h6">Punkter</Typography>
        <List>
          {session.map((item, key) => (
            <ChecklistOverviewListItem
              checklistPoint={item}
              key={key}
              sessionData={sessionData}
              sessionEvents={sessionEvents}
              assignedId={checklist.assignedId}
            />
          ))}

          <Typography variant="h6">Avvik</Typography>
          {
            sessionDeviations.map((deviationItem, key) => {
              if (
                deviationItem.type === "TemperatureDeviationReported" ||
                deviationItem.type === "DeviationReported"
              )
                return (
                  <DeviationAccordion
                    tenantId={tenantId.toString()}
                    item={deviationItem}
                    key={
                      key + deviationItem.sessionId + deviationItem.checklistId
                    }
                    isAutoGenerated={!deviationItem.signatureId}
                    pointNameRegistry={pointNameRegistry}
                  />
                );
              else {
                return (
                  <ChecklistDeviationAccordion
                    tenantId={tenantId.toString()}
                    isAutoGenerated={
                      deviationItem.type === "ChecklistDeviationDetected"
                    }
                    item={deviationItem}
                    key={
                      key + deviationItem.sessionId + deviationItem.checklistId
                    }
                    comment={deviationItem.type === "ChecklistComment"}
                  />
                );
              }
            })
          }

          <Typography variant="h6">Kommentarer</Typography>
          {comments?.map((e, i) => (
            <ChecklistCommentOrDeviation key={`${e.id}${i}`} e={e} />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default ChecklistOverviewCard;
