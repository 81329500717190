import React, { FC, useEffect, useState } from "react";
import { Form, Formik } from "formik";

import {
  Button,
  FormControlLabel,
  Paper,
  Switch,
  Theme,
  Typography,
  Grid,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useAddAllwaysScheduled, useAddSchedule } from "../../services/checklistService";
import { useChecklistContext } from "../../ChecklistReactWrapper";
import { Site } from "../../../entity/site";
import CronDefinitionField from "../cron-definition/CronDefinitionField";
import { getNextDatesFromCronStatement } from "../cron-definition/util";
import RelativeCronDefinitionField from "../cron-definition/RelativeCronDefinitionField";
import SimpleCronDefinitionField, {
  isCronGeneratedInSimpleUi,
} from "../cron-definition/SimpleCronDefinitionField";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  checklist: any;
  onSubmit: (scheduleCreatedEvent: any[]) => any;
};

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      padding: spacing(2),
    },
    title: {
      marginBottom: spacing(2),
    },
    textField: {
      "& > div": {
        background: palette.common.white,
      },
    },
  })
);

type UpcomingTimesProps = {
  cronStatement: string;
  onChange: (length: number) => any;
};

const UpcomingTimes: FC<UpcomingTimesProps> = ({ cronStatement, onChange }) => {
  const { t } = useTranslation();

  const nextDates = getNextDatesFromCronStatement(cronStatement);

  useEffect(() => onChange(nextDates.length), [nextDates]);
  return nextDates?.length ? (
    <>
      <br />
      <br />
      {t("editSchedule.upcoming_times")}:<span> </span>
      {nextDates.map((item) => item.format("HH:mm DD.MM.YYYY")).join(", ")}...
    </>
  ) : (
    <p style={{ color: "red" }}>{t("editSchedule.upcoming_times_invalid")}</p>
  );
};

const EditSchedule: FC<Props> = ({ checklist, onSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user, siteService } = useChecklistContext();
  const [tenantId, setTenantId] = useState<string>("");
  useEffect(() => {
    siteService.getSite(user.siteId).subscribe((site: Site) => {
      setTenantId(site.tenantId.toString());
    });
  }, []);

  const { visibleFrom, todoFrom, dueFrom, hideFrom, schedulingEnabled, isAllwaysSchedule } =
    checklist.schedule;
  const [isScheduleDefined, setIsScheduleDefined] = useState(
    checklist.isScheduleDefined
  );

  const [visibleFromTime, setVisibleFromTime] = useState(visibleFrom || "");
  const [todoFromCron, setTodoFromCron] = useState(todoFrom || "");
  const [todoFromCronSimple, setTodoFromCronSimple] = useState(todoFrom || "");
  const [dueFromTime, setDueFromTime] = useState(dueFrom || "");
  const [hideFromTime, setHideFromTime] = useState(hideFrom || "");
  const [isSchedulingEnabled, setSchedulingEnabled] = useState(
    schedulingEnabled ?? false
  );
  const [isAllwaysActive, setAllwaysActive] = useState(isAllwaysSchedule);
  const [isAdvanced, setAdvanced] = useState(
    todoFrom ? !isCronGeneratedInSimpleUi(todoFrom) : false
  );
  const [isCronValid, setCronValid] = useState(false);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const scheduleChanged =
    visibleFromTime !== visibleFrom ||
    todoFromCron !== todoFrom ||
    todoFromCronSimple !== todoFrom ||
    dueFromTime !== dueFrom ||
    hideFromTime !== hideFrom ||
    isSchedulingEnabled !== schedulingEnabled;

  const handleSubmit = (values, { resetForm }) => {
    addSchedule();
    resetForm();
  };

  const addSchedule = async () => {
    let r;
    if (isAllwaysActive) {
      r = await useAddAllwaysScheduled({
        assignedId: checklist.assignedId,
        checklistId: checklist.checklistId,
        tenantId: tenantId,
        siteId: user.siteId,
        schedulingEnabled: isSchedulingEnabled,
      });
    }
    else {
      r = await useAddSchedule({
        assignedId: checklist.assignedId,
        checklistId: checklist.checklistId,
        tenantId: tenantId,
        siteId: user.siteId,
        visibleFrom: visibleFromTime,
        todoFrom: isAdvanced ? todoFromCron : todoFromCronSimple,
        dueFrom: dueFromTime,
        hideFrom: hideFromTime,
        schedulingEnabled: isSchedulingEnabled,

      });
    }
    setAlertOpen(true);
    onSubmit([r]);
  };

  const CronDefinitionComponent = isAdvanced
    ? CronDefinitionField
    : SimpleCronDefinitionField;

  return (
    <>
      {isScheduleDefined ? (
        <div className={classes.root}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={isAllwaysActive}
                  onChange={(_, v) => setAllwaysActive(v)}
                  color="primary"
                />
              }
              label={t("editSchedule.createAllwaysActive") as string}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isSchedulingEnabled}
                  onChange={(_, v) => {
                    setSchedulingEnabled(!isSchedulingEnabled);
                  }}
                  color="primary"
                />
              }
              label={t("editSchedule.schedulingEnabled") as string}
            />
          </Box>
          <Formik initialValues={{}} onSubmit={handleSubmit}>

            {() => (
              <Form>
                {!isAllwaysActive && (
                  <RelativeCronDefinitionField
                    prefix={`${t("editSchedule.the_checklist")} ${t(
                      "editSchedule.should_become"
                    )} ${t("editSchedule.visible")} `}
                    value={visibleFromTime}
                    onChange={setVisibleFromTime}
                    suffix={`${t("editSchedule.before")} ${t(
                      "editSchedule.it_becomes"
                    )} ${t("editSchedule.available")} ${t(
                      "editSchedule.for_doing"
                    )}.`}
                  />
                )}
                {!isAllwaysActive && (<>
                  <Paper style={{ padding: "1em", margin: "0.5em" }}>
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    ></Grid>
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isAdvanced}
                            onChange={(_, v) => setAdvanced(v)}
                            color="primary"
                          />
                        }
                        label={t("editSchedule.advanced") as string}
                      />
                    </Grid>
                    <CronDefinitionComponent
                      prefix={`${t("editSchedule.the_checklist")} ${t(
                        "editSchedule.starts_on"
                      )} `}
                      value={isAdvanced ? todoFromCron : todoFromCronSimple}
                      onChange={
                        isAdvanced ? setTodoFromCron : setTodoFromCronSimple
                      }
                      suffix={
                        <UpcomingTimes
                          cronStatement={
                            isAdvanced ? todoFromCron : todoFromCronSimple
                          }
                          onChange={(length) => setCronValid(!!length)}
                        />
                      }
                    />
                  </Paper>

                  <RelativeCronDefinitionField
                    prefix={`${t("editSchedule.the_checklist")} ${t(
                      "editSchedule.should_become"
                    )} ${t("editSchedule.overdue")} `}
                    value={dueFromTime}
                    onChange={setDueFromTime}
                    suffix={`${t("editSchedule.after")} ${t(
                      "editSchedule.it_becomes"
                    )} ${t("editSchedule.available")} ${t(
                      "editSchedule.for_doing"
                    )}.`}
                  />
                  <RelativeCronDefinitionField
                    prefix={`${t("editSchedule.the_checklist")} ${t(
                      "editSchedule.should_become"
                    )} ${t("editSchedule.hidden")} `}
                    value={hideFromTime}
                    onChange={setHideFromTime}
                    suffix={`${t("editSchedule.after")} ${t(
                      "editSchedule.it_becomes"
                    )} ${t("editSchedule.overdue")}.`}
                  />
                </>)}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isCronValid && !isAllwaysActive || !scheduleChanged}
                >
                  {t("editSchedule.save")}
                </Button>
              </Form>
            )}

          </Formik>
        </div>
      ) : (
        <div>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              setIsScheduleDefined(true);
              setSchedulingEnabled(true);
            }}
          >
            {t("editSchedule.add")}
          </Button>
        </div>
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ height: "100%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleAlertClose} severity="success">
          <b>{t("editSchedule.saved")}</b>
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditSchedule;
