import { Theme, Button } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import * as React from "react";
import { useParams } from "react-router-dom";
import {
  mapChecklist,
  mapSessions,
  useGetAllPointEvents,
  useGetAssignedChecklistComposite,
  useGetSessions,
} from "../../services/checklistService";
import ChecklistOverviewCard from "./ChecklistOverviewCard";
import PageHeader from "../../../react-components/PageHeader";
import { useTranslation } from "react-i18next";
import { useChecklistDescribedRegistry } from "../deviations/useChecklistDescribedRegistry";
import { Deviation, useChecklistDeviations } from "../deviations/useChecklistDeviations";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useChecklistContext } from "../../ChecklistReactWrapper";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {},
    button: {
      marginBottom: 10,
    },
    dateFilter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "0.5em",
    },
    periodTitle: {
      minWidth: "12em",
      textAlign: "center",
    }
  })
);

const ChecklistOverviewPage = () => {
  let { assignedId }: any = useParams();

  const [contextUpdate, setContextUpdate] = useState(0);

  const classes = useStyles();
  const { t } = useTranslation(); //translation

  const [{ data: sessionData, loading: sessionLoading, error: sessionError }] =
    useGetSessions(assignedId);

  const { user } = useChecklistContext();

  const [
    { data: checklistData, loading: checklistLoading, error: checklistError },
  ] = useGetAssignedChecklistComposite(assignedId);

  const checklistDescribedRegistryForAssignedId = useChecklistDescribedRegistry([assignedId], checklistData ?? []);

  const query = new URLSearchParams(window.location.search);
  const { from, to } = useMemo(() => {
    if (query.get("from") && query.get("to")) {
      const from = DateTime.fromISO(query.get("from")).startOf("day");
      const to = DateTime.fromISO(query.get("to")).endOf("day");
      return { from, to };
    }
    return { from: null, to: null };
  }, [query, contextUpdate]);

  const sessionsUnfiltered = mapSessions(
    sessionData?.filter((item) => item.type != "ScheduleDue") ?? []
  )

  const sessions = sessionsUnfiltered.filter(session => {
    if (from && to) {
      const timestamp = DateTime.fromISO(session.timestamp);
      return timestamp >= from && timestamp <= to;
    }
    return true;
  });

  console.log(sessions, sessionsUnfiltered, from, to, from <= to);

  // one year before
  const [refDateStart] = useState(DateTime.now().minus({ years: 10 }));

  const { allDeviations, deviationLoading } = useChecklistDeviations({
    assignedIds: [assignedId],
    checklistDescribedRegistryForAssignedId,
    refDateStart,
  });

  const deviations = React.useMemo(() => {
    const result: Deviation[] = [];
    allDeviations.forEach((deviation) => {
      Object.values(deviation.deviations).forEach((devs: Deviation[]) => {
        result.push(...devs);
      });
    });
    return result;
  }, [allDeviations]);

  const [{ data: points, loading: pointsLoading, error: pointsError }] =
    useGetAllPointEvents(user.siteId);

  const pointNameRegistry = {};
  points?.forEach((point) => {
    if (point.name) {
      const sitePoint = points.filter(p => p.pointId === point.pointId).reverse().find(p => p.type === "SitePointDescribed");
      pointNameRegistry[point.pointId] = sitePoint?.name || point.name;
    }
  });

  if (deviationLoading) {
    return <p>{t("Loading")}</p>;
  }

  if (sessionLoading || checklistLoading) {
    return <p>{t("Loading")}</p>;
  }
  if (sessionError || checklistError) {
    return <p>{t("Error")}</p>;
  }

  const checklist = mapChecklist(checklistData, assignedId);

  const handlePeriodChange = (multiplier: -1 | 1) => {
    const diffInDays = Math.ceil(Math.abs(to.diff(from, "days").days)) * multiplier;
    const newFrom = from.plus({ days: diffInDays });
    const newTo = to.plus({ days: diffInDays });
    // set from & to in query without page reload
    const url = new URL(window.location.href);
    url.searchParams.set("from", newFrom.toISODate());
    url.searchParams.set("to", newTo.toISODate());
    history.pushState(null, "", url.toString());
    setContextUpdate(c => c + 1);
  }

  return (
    <>
      <PageHeader title={`Rapport for ${checklist.siteName || checklist.name}`} href="/checklist/dashboard/overview" />
      {from && to && <div className={classes.dateFilter}>
        <Button
          onClick={() => {
            handlePeriodChange(-1);
          }}
        >
          <ArrowBackIos />
        </Button>

        <div className={classes.periodTitle}>
          {from.toFormat("dd.MM.yyyy.")} - {to.toFormat("dd.MM.yyyy.")}
        </div>

        <Button
          onClick={() => {
            handlePeriodChange(1);
          }}
        >
          <ArrowForwardIos />
        </Button>
      </div>}
      {sessions.map((item, key) => (
        <ChecklistOverviewCard
          key={key}
          checklist={checklist}
          sessionData={item}
          sessionDeviations={deviations.filter((d) => d.sessionId === item.id)}
          pointNameRegistry={pointNameRegistry}
        />
      ))}
      {sessions.length == 0 && <p>{t("checklist.noSessions")}</p>}
    </>
  );
};

export default ChecklistOverviewPage;
