import {
  Paper,
  Theme,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Tooltip,
  Snackbar,
  Alert
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import EditIcon from "@mui/icons-material/Edit";
import DialogTitle from "../../react-components/DialogTitle";
import ManualScheduleStart from "./ManualScheduleStart";
import EventNoteIcon from '@mui/icons-material/EventNote';
import DeleteIcon from '@mui/icons-material/Delete';
import { createRoleChecker, TmeshRoles } from "../../auth-utils";
import { useChecklistContext } from "../ChecklistReactWrapper";
import DeleteChecklist from "./DeleteChecklist";
import { ChecklistSumary } from "../services/checklistService";

type Props = {
  checklist: ChecklistSumary;
  onChange: () => void;
};

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {},
    card: {
      padding: spacing(2),
      marginTop: spacing(2),
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      position: "absolute",
    },
    link: {
      color: "grey",
    },
    title: {
      color: "black"
    }
  })
);

const ChecklistOverviewCard = ({ checklist, onChange }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  const { user } = useChecklistContext();
  const { t } = useTranslation();
  const handleDelete = () => {
    setDeleteOpen(false);
    onChange();
  }
  const toggleDeleteOpen = () => {
    setDeleteOpen(!deleteOpen);

  }
  const toggleOpen = () => {
    setOpen(!open);
  };
  const hasRole = createRoleChecker(user?.adalUser?.roles);
  const isAdmin = hasRole(TmeshRoles.Admin);
  return (
    <div>
      <Paper className={classes.card}>
        <div>
          <Link
            to={`/checklist/overview/details/${checklist.assignedId}?backTo=/checklist/dashboard/overview`}
            key={checklist.assignedId}
          >
            <Typography variant="h3" className={classes.title}>{checklist.name}</Typography>
          </Link>
        </div>
        <div>
          {isAdmin &&
            <>
              <Tooltip title={t('checklist.actions.delete')}>
                <IconButton onClick={toggleDeleteOpen}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Dialog open={deleteOpen} onClose={toggleDeleteOpen}>
                <DialogTitle onClose={toggleDeleteOpen}>
                  {t('checklist.actions.delete')}
                </DialogTitle>
                <DialogContent>
                  <DeleteChecklist onDeleted={handleDelete} onCanceled={toggleDeleteOpen} checklist={checklist} />
                </DialogContent>
              </Dialog>
            </>}
          <Tooltip title={t('checklist.actions.startChecklist')}>
            <IconButton onClick={toggleOpen}>
              <PlayCircleFilledWhiteIcon />
            </IconButton>
          </Tooltip>
          <Dialog open={open} onClose={toggleOpen}>
            <DialogTitle onClose={toggleOpen}>
              {t("manualSchedule.startNow")}
            </DialogTitle>
            <DialogContent>
              <ManualScheduleStart
                checklist={checklist}
                onSuccess={() => {
                  toggleOpen();
                  setSuccessOpen(true);
                }}
              ></ManualScheduleStart>
            </DialogContent>
          </Dialog>
          <Link
            className={classes.link}
            to={`/checklist/overview/${checklist.assignedId}`}
          >
            <Tooltip title={t('checklist.actions.reports')}>
              <IconButton>
                <EventNoteIcon sx={{ color: checklist.schedulingEnabled ? 'green' : 'gray' }} />
              </IconButton>
            </Tooltip>
          </Link>
          {isAdmin && <Link
            className={classes.link}
            to={`/edit-checklist/${checklist.assignedId}`}
          >
            <Tooltip title={t('checklist.actions.edit')}>
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Link>}
        </div>
      </Paper>

      <Snackbar sx={{ height: "100%" }} open={successOpen} autoHideDuration={3000} onClose={() => setSuccessOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSuccessOpen(false)} severity="success" sx={{ width: '100%' }}>
          <b>{t("manualSchedule.successMessage")}</b>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ChecklistOverviewCard;
