import {
  AccordionDetails,
  AccordionSummary,
  Theme,
  Typography,
  Grid,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Accordion } from "@mui/material";
import clsx from "clsx";
import React, { FC, useState } from "react";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import CommentIcon from "@mui/icons-material/Comment";
import HandledIcon from "@mui/icons-material/CheckCircle";
import CachedIcon from "@mui/icons-material/Cached";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import MediaRender from "../../../react-components/MediaRender";
import {
  BASE_CHECKLIST_MEDIA_PATH,
  FILEUPLOAD_URL,
} from "../../services/checklistService";
import { fetchSignature } from "../../services/signatureService";
import { DeviationHandledComments } from "./DeviationHandledComments";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    deviationCard: {
      padding: spacing(2),
      marginBottom: spacing(2),
    },
    summary: {
      display: "flex",
    },
    time: {
      width: 100,
    },
    details: {
      display: "block",
    },
    alert: {
      background: "rgb(255, 244, 229)",
    },
    icon: {
      marginRight: spacing(2),
    },
    warningIcon: {
      color: palette.warning.main,
    },
    autoIcon: {
      marginLeft: spacing(2),
      color: palette.info.main,
    },
    handledIcon: {
      color: palette.success.main,
    },
    mediaContainer: {
      display: "block",
      padding: "2vh",
    },
    commentIcon: {
      color: "gray",
    },
  })
);

type Props = {
  item: any;
  isAutoGenerated: boolean;
  tenantId: string;
  comment?;
};

const ChecklistDeviationAccordion: FC<Props> = ({
  item,
  isAutoGenerated,
  tenantId,
  comment,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [handled, setHandled] = useState(item.handled);
  const [handledBy, setHandledBy] = useState(item.handledSignatureId);
  const [expanded, setExpanded] = useState(!handled);
  const isComment = !!comment ? comment : false;

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  const [signature, setSignature] = useState(null);
  React.useEffect(() => {
    if (item.signatureId) {
      fetchSignature(item.signatureId)
        .then(setSignature)
        .catch((err) => console.log(`Failed to fetch signature`, err));
    }
  }, [item.signatureId]);

  const [handledSignature, setHandledSignature] = useState(null);
  React.useEffect(() => {
    if (handledBy) {
      fetchSignature(handledBy)
        .then(setHandledSignature)
        .catch((err) => {
          console.error(`Failed to fetch handled signature LUKA`, err);
        });
    }
  }, [handledBy, handled]);

  return (
    <Accordion expanded={expanded} className={clsx(classes.deviationCard)}>
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        className={classes.summary}
        onClick={handleExpanded}
      >
        <Typography className={classes.time}>
          <b>
            {DateTime.fromISO(item.timestamp).toLocaleString(
              DateTime.TIME_SIMPLE
            )}
          </b>
        </Typography>
        {isComment ? (
          <CommentIcon className={clsx(classes.commentIcon, classes.icon)} />
        ) : handled ? (
          <HandledIcon className={clsx(classes.handledIcon, classes.icon)} />
        ) : (
          <WarningIcon className={clsx(classes.warningIcon, classes.icon)} />
        )}
        {isAutoGenerated && (
          <>
            <CachedIcon className={classes.autoIcon} />
            <Typography>{t("checklist.deviationCard.auto")}</Typography>
          </>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {isComment ? (
          <Typography gutterBottom>
            <b>{t("checklist.comments.wholeRoutine")}</b>
          </Typography>
        ) : (
          <Typography gutterBottom>
            <b>{t("checklist.deviationCard.wholeRoutine")}</b>
          </Typography>
        )}
        {signature && (
          <p>
            {t("checklist.deviationCard.reportedBy")}: <i>{signature?.name}</i>
          </p>
        )}
        <Typography gutterBottom>
          {isComment
            ? t("checklist.comments.comment")
            : t("checklist.deviationCard.comment")}
          :{" "}
          {t(item.comment.replace(/\.$/g, ""))}
        </Typography>
        <Grid container>
          {item.images?.length ? (
            item.images.map((imgPath) => (
              <Grid
                key={imgPath}
                className={classes.mediaContainer}
                item
                xs={6}
              >
                <MediaRender
                  src={`${FILEUPLOAD_URL}/${BASE_CHECKLIST_MEDIA_PATH}/${imgPath}`}
                />
              </Grid>
            ))
          ) : (
            <></>
          )}
        </Grid>
        {handledSignature && (
          <p>
            {t("checklist.deviationCard.handledBy")}:{" "}
            <i>{handledSignature?.name}</i>
          </p>
        )}

        <DeviationHandledComments
          tenantId={tenantId}
          deviation={item}
          onAddComment={(handledEvent) => {
            //
          }}
          onHandle={(handledEvent) => {
            setHandled(true);
            setHandledBy(handledEvent.signatureId);
          }}
          comment={isComment}
          variant="checklist"
        />

      </AccordionDetails>
    </Accordion>
  );
};

export default ChecklistDeviationAccordion;
