import * as React from "react";
import { useState, FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Theme,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { actions } from "../services/reducer";
import FormikField from "../../react-components/FormikField";
import FileUpload, {
  extractExtension,
} from "../../react-components/FileUpload/FileUpload";
import { AttachmentFile } from "../../react-components/FileUpload/attachment";
import CommentIcon from "@mui/icons-material/ChatBubble";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { useSignatureContext } from "../../signature-react/SignatureContext";
import DialogTitle from "../../react-components/DialogTitle";
import MediaRender from "../../react-components/MediaRender";
import { Guid } from "../../system/guid";
import {
  BASE_CHECKLIST_MEDIA_PATH,
  FILEUPLOAD_URL,
  useImageUpload,
} from "../services/checklistService";
import { useTranslation } from "react-i18next";
import WarningIcon from "@mui/icons-material/Warning";
import { ProgressBar } from "../../react-components/ProgressBar";

type Props = {
  dispatch: any;
  pointId?: any;
  assignedId: string;
  checklistId: string;
  variant: "Point" | "Checklist";
  reportedDeviationEvents: any[];
};

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {},
    dialogContent: {
      paddingBottom: "1rem",
    },
    typography: {
      color: palette.primary.main,
      fontSize: 12,
      position: "absolute",
      top: "15%",
    },
    paper: {
      backgroundColor: palette.grey[300],
      marginBottom: spacing(2),
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    mediaContainer: {
      display: "block",
      padding: "2vh",
    },
    image: {},
    comment: {
      padding: spacing(2),
      minHeight: spacing(4),
    },
    icon: {
      marginRight: spacing(2),
      color: "gray",
    },
  })
);

const ReportDeviationDialog: FC<Props> = ({
  dispatch,
  pointId,
  assignedId,
  checklistId,
  variant,
  reportedDeviationEvents,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useChecklistContext();
  const [open, setOpen] = useState<boolean>(false);
  const {
    state: { activeSignature },
  } = useSignatureContext();

  const signatureId = activeSignature?.id?.toString();
  const toggleOpen = () => {
    setOpen(!open);
  };

  const [checked, setChecked] = useState<boolean>(false);

  const handleChecked = () => {
    setChecked(!checked);
  };

  const [comments, setComments] = useState<any[]>(
    reportedDeviationEvents || []
  );

  const [attachments, setAttachments] = useState<AttachmentFile>();
  const [progress, setProgress] = React.useState<number>();

  const addComment = (comment) => setComments([...comments, comment]);

  const handleMediaUpload = async (onSuccess: (images: string[]) => void) => {
    const img = AttachmentFile(attachments);

    if (img.hasAttachments) {
      const images = await Promise.all(
        img.files.map(async (file) => {
          const imgId = Guid.newGuid().toString();
          const extension = extractExtension(file.name);
          await useImageUpload(img.dataFor(file), imgId, extension, BASE_CHECKLIST_MEDIA_PATH, setProgress);
          return `${imgId}${extension}`;
        })
      );
      onSuccess(images);
    } else {
      onSuccess([]);
    }
  };

  const numberOfComments = comments?.length ?? 0;

  return (
    <>
      <Button
        variant="contained"
        onClick={toggleOpen}
        startIcon={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography className={classes.typography}>
              {numberOfComments || ""}
            </Typography>
            <CommentIcon sx={{ color: "#fff" }} />
          </div>
        }
      >
        {t("Deviations")} / {t("Comment")}
      </Button>
      <Dialog open={open} onClose={toggleOpen} fullWidth maxWidth="md">
        <DialogTitle onClose={toggleOpen}>
          {t("Deviations")} / {t("Comment")}
        </DialogTitle>
        <Formik
          initialValues={{ comment: "" }}
          onSubmit={(values, { resetForm }) => {
            if (!values.comment) return;
            handleMediaUpload((images) => {
              if (checked) {
                addComment({
                  comment: values.comment,
                  type: "ChecklistDeviationReported",
                  images,
                });
                dispatch({
                  type:
                    variant === "Point"
                      ? actions.REPORT_DEVIATION
                      : actions.CHECKLIST_REPORT_DEVIATION,
                  payload: {
                    deviation: {
                      ...(variant === "Point" ? [pointId] : []),
                      comment: values.comment,
                      assignedId,
                      checklistId,
                      images,
                      signatureId: signatureId,
                      siteId: user.siteId,
                    },
                    userId: user.adalUser.oid,
                  },
                });
              } else {
                addComment({
                  comment: values.comment,
                  type: "ChecklistComment",
                  images,
                });
                dispatch({
                  type: actions.CHECKLIST_COMMENT,
                  payload: {
                    userId: user.adalUser.oid,
                    data: {
                      comment: values.comment,
                      assignedId,
                      checklistId,
                      images,
                      signatureId: signatureId,
                      siteId: user.siteId,
                    },
                  },
                });
              }
            });

            setAttachments(null);
            resetForm();
          }}
        >
          {() => (
            <Form>
              <DialogContent className={classes.dialogContent}>
                <FormikField
                  name="comment"
                  label={t("deviationOrComment")}
                  multiline
                  autoFocus
                />
                <FormControlLabel
                  label={String(t("checklist.deviation"))}
                  control={
                    <Checkbox checked={checked} onChange={handleChecked} />
                  }
                />
                <FileUpload
                  multiple
                  attachments={AttachmentFile(attachments)}
                  onAttachmentsChange={(value) => setAttachments(value)}
                />
                {progress != null && <ProgressBar value={progress} />}
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary">
                  {t("Add")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
        {comments?.map((e, i) => (
          <ChecklistCommentOrDeviation key={`${e.id}${i}`} e={e} />
        ))}
      </Dialog>
    </>
  );
};

export const ChecklistCommentOrDeviation: FC<{ e: any }> = ({ e }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.comment}>
            {e.type === "ChecklistComment" && (
              <CommentIcon className={classes.icon} />
            )}
            {e.type === "ChecklistDeviationReported" && (
              <WarningIcon className={classes.icon} />
            )}
            {e.comment}
          </div>
        </Grid>
        {e.images?.length ? (
          e.images.map((imgPath) => (
            <Grid
              key={imgPath}
              className={classes.mediaContainer}
              item
              xs={6}
            >
              <MediaRender
                className={classes.image}
                src={`${FILEUPLOAD_URL}/${BASE_CHECKLIST_MEDIA_PATH}/${imgPath}`}
              />
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Grid>
    </Paper>
  )
}

export default ReportDeviationDialog;
