import React from "react";
import { useTranslation } from "react-i18next";
import { isValidExtension, SupportedMediaFormatsType, extractExtension } from "./FileUpload/FileUpload";

type Props = {
  src: string;
  className?: string;
};

const MediaFormatRenderContainer: {[key in SupportedMediaFormatsType]: React.FC<Props>} = {
  '.jpg': ({ src, className }) => <img src={src} className={className} style={{width: '100%'}} />,
  '.jpeg': ({ src, className }) => <img src={src} className={className} style={{width: '100%'}} />,
  '.gif': ({ src, className }) => <img src={src} className={className} style={{width: '100%'}} />,
  '.png': ({ src, className }) => <img src={src} className={className} style={{width: '100%'}} />,
  '.apng': ({ src, className }) => <img src={src} className={className} style={{width: '100%'}} />,
  '.svg': ({ src, className }) => <img src={src} className={className} style={{width: '100%'}} />,
  '.bmp': ({ src, className }) => <img src={src} className={className} style={{width: '100%'}} />,
  '.ico': ({ src, className }) => <img src={src} className={className} style={{width: '100%'}} />,

  '.mp4': ({ src, className }) => 
    <video controls preload="auto" style={{width: '100%'}}>
      <source
        src={src}
        className={className}
        type="video/mp4"
      />
    </video>,
  '.webm': ({ src, className }) => 
    <video controls preload="auto" style={{width: '100%'}}>
      <source
        src={src}
        className={className}
        type="video/webm"
      />
    </video>,
  '.ogg': ({ src, className }) => 
    <video controls preload="auto" style={{width: '100%'}}>
      <source
        src={src}
        className={className}
        type="video/ogg"
      />
    </video>,
}

const MediaRender: React.FC<Props> = ({ src, className='' }) => {
  const { t } = useTranslation();

  const extension = extractExtension(src);
  if(!extension || !isValidExtension(src)) return t("Unsupported media format");

  return MediaFormatRenderContainer[extension]({ src, className });
};

export default MediaRender;
